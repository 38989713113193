import React, { useRef } from "react";
import classes from './Menu.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Menu/image_01.png';
import image_02 from '../../../assets/img/HomePage/Menu/image_02.png';
import image_03 from '../../../assets/img/HomePage/Menu/image_03.png';
import image_04 from '../../../assets/img/HomePage/Menu/image_04.png';
import Button from "../../GlobalComponents/Button/Button";

const Menu = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cards = [
        {
            title: 'home_menu_title_01',
            route: 'menu.pdf',
            image: image_01,
            ref: cardRef_01,
        },
        {
            title: 'home_menu_title_02',
            route: 'menu.pdf',
            image: image_02,
            ref: cardRef_02,
        },
        {
            title: 'home_menu_title_03',
            route: 'bar.pdf',
            image: image_03,
            ref: cardRef_03,
        },
        {
            title: 'home_menu_title_04',
            route: 'bar.pdf',
            image: image_04,
            ref: cardRef_04,
        },
    ];
    return (
        <section className="mt container">
            <div className={classes.menu}>
                <h2 className={`${classes.title} font-40`}>
                    {t('home_menu_title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div 
                            ref={card.ref} 
                            key={index} 
                            className={classes.card}
                        >
                            <img className={classes.cardImage} src={card.image} alt='' />
                            <h5 className={`${classes.cardTitle} font-20`}>
                                {t(card.title)}
                            </h5>
                            <div className={classes.btn}>
                                <Button secondary route={card.route}>
                                    {t('btn_view')}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Menu;
