import React from "react";
import Banner from "../../components/HomePage/Banner/Banner";
import Header from '../../components/GlobalComponents/Header/Header';
import Menu from "../../components/HomePage/Menu/Menu";
import Slider from "../../components/HomePage/Slider/Slider";
import Footer from '../../components/GlobalComponents/Footer/Footer';
// import ReserveTable from "../../components/HomePage/ReserveTable/ReserveTable";

const HomePage = () => {
    return (
        <div>
            <Header />
            <div className="ovf-hidden">
                <Banner />
                <Slider />
                <Menu />
                {/* <ReserveTable /> */}
            </div>
            <Footer />
        </div>
    );
};

export default HomePage;
